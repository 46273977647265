var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Feedback',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Otherwise known as off-canvas or a side drawer, BootstrapVue's custom "),_c('code',[_vm._v("<b-sidebar>")]),_vm._v(" component is a fixed-position toggleable slide out box, which can be used for navigation, menus, details, etc. It can be positioned on either the left (default) or right of the viewport, with optional backdrop support. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('placement-sidebar')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('hiding')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('backdrop')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }